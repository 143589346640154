import React from 'react'

export default function Arrow({color}) {
  return (
    <>
      <svg width="26" height="15" viewBox="0 0 26 15" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.6452 0.302968C17.741 0.206931 17.8548 0.130736 17.9801 0.078748C18.1054 0.0267597 18.2397 0 18.3753 0C18.511 0 18.6453 0.0267597 18.7706 0.078748C18.8958 0.130736 19.0096 0.206931 19.1054 0.302968L25.2929 6.49047C25.389 6.58626 25.4652 6.70006 25.5172 6.82535C25.5691 6.95063 25.5959 7.08495 25.5959 7.22059C25.5959 7.35624 25.5691 7.49055 25.5172 7.61584C25.4652 7.74112 25.389 7.85492 25.2929 7.95072L19.1054 14.1382C18.9118 14.3319 18.6492 14.4406 18.3753 14.4406C18.1015 14.4406 17.8388 14.3319 17.6452 14.1382C17.4516 13.9446 17.3428 13.6819 17.3428 13.4081C17.3428 13.1342 17.4516 12.8716 17.6452 12.678L23.1046 7.22059L17.6452 1.76322C17.5492 1.66742 17.473 1.55362 17.421 1.42834C17.369 1.30305 17.3422 1.16874 17.3422 1.03309C17.3422 0.897448 17.369 0.763135 17.421 0.637848C17.473 0.512562 17.5492 0.398762 17.6452 0.302968Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M0.844055 7.22058C0.844055 6.94708 0.952705 6.68477 1.1461 6.49138C1.3395 6.29798 1.6018 6.18933 1.87531 6.18933H23.5316C23.8051 6.18933 24.0674 6.29798 24.2608 6.49138C24.4542 6.68477 24.5628 6.94708 24.5628 7.22058C24.5628 7.49409 24.4542 7.75639 24.2608 7.94979C24.0674 8.14318 23.8051 8.25183 23.5316 8.25183H1.87531C1.6018 8.25183 1.3395 8.14318 1.1461 7.94979C0.952705 7.75639 0.844055 7.49409 0.844055 7.22058Z" />
      </svg>

    </>
  )
}
