import { useEffect } from 'react'

export const useHeight = () => {

  const resizeHandler = () => {
    let vh = window.innerHeight
    document.documentElement.style.setProperty("--vh", `${vh}px`)
   }

  useEffect(() => {

    resizeHandler()
   window.addEventListener('resize', resizeHandler)

   return () => {
     window.removeEventListener('resize', resizeHandler)
   }
 }, [])
 
}
