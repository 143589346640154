import React, { useState } from 'react'
import { Button } from '../Button/Button'
import { fetchData } from '../fetchData'
import {resetScroll} from '../../utils/resetScroll'
import './Form.scss'

export function Form({ data, alert, hideAlert }) {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
  })

  const inputs = []

  const focusHandler = (e) => {
    e.target.classList.add('focus')
  } 

  const blurHandler = (e) => {
    e.target.classList.remove('focus')
    e.target.classList.remove('error')
    resetScroll()
  } 

  const validation = (type) => {
    if (type === 'error') {
      alert(['error'], 'Некоректний номер телефону')
      inputs[1].focus()
      inputs[1].classList.add('error')
    } else {
      alert(['success'], 'Заявка успішно надіслана')
    }
    
  }
  const submitHandler = (e) => {

    e.preventDefault()

    if(formData.phone.length < 4) {
      
      validation('error')
    } else {

      hideAlert()
      fetchData([data, formData], validation.bind(null, 'success'))
      
      const newFormData = { ...formData }
      
      Object.keys(newFormData).forEach(key => {
        newFormData[key] = ''
      })

      setFormData(newFormData)

      inputs.forEach(el => el.classList.remove('active'))
    }
  }

  const inputHandler = (e) => {
    
    const el = e.target
    let value = el.value.trim()
    const attr = e.target.getAttribute('id')

    el.classList.remove('error')

    if(attr === 'phone') {
      value = value.replace(/[A-z]|[А-я]|[*!@#$%^&{}[\]~""/|=]/g, '')
    }
    
    value.length > 0
      ? el.classList.add('active')
      : el.classList.remove('active')

    const newFormData = { ...formData }
    newFormData[attr] = value
    setFormData(newFormData)
  }

  return (
    <div className='form-wrapper'>
      <form onSubmit={submitHandler} className='form'>

        <div className='form__input-wrapper'>
          <input
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputHandler}
            id='name'
            name='Имя'
            type='text'
            className='form__input'
            value={formData.name}
            ref={input => inputs.push(input)}
          />
          <label htmlFor='name' className='form__label'>
            Ім'я
          </label>
        </div>

        <div className='form__input-wrapper'>
          <input
            onBlur={blurHandler}
            onFocus={focusHandler}
            data-required
            onChange={inputHandler}
            id='phone'
            name='Телефон'
            type='tel'
            className='form__input'
            value={formData.phone}
            ref={input => inputs.push(input)}
          />
          <label htmlFor='phone' className='form__label'>
            Телефон
          </label>
        </div>
        {/* <span className="form__privacy">
          Отправляя эту форму, вы даёте согласие на обработку ваших персональных данных
        </span> */}
        <Button
          class={'form__btn'}
          type={'submit'}
          text={'Хочу крутий сайт!'}
        />
      </form>
    </div>
  )
}
