import gsap from 'gsap'

export const animateIn = () => {
  const timeout = setTimeout(() => {
    const alert = document.querySelector('.alert')  
    alert && gsap.to(alert, { duration: 0.3, y: 0, opacity: 1})
    clearTimeout(timeout)
  }, 10)
}

export const animateOut = (cb) => {
  const alert = document.querySelector('.alert')  
  alert && gsap.to(alert, 
    { 
      duration: 0.3, 
      y: -60, 
      opacity: 0, 
      onComplete: cb
})
}