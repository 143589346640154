import React from 'react'
import './Alert.scss'

export function Alert(props) {
  return (
    <div onClick={props.hide} className={`alert ${props.classes.join(' ')}`}>
      <span className="alert__text">{props.text}</span>
    </div>
  )
}
