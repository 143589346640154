import React from 'react'

export function Logo() {
  return (
    <div className="logo">
      <svg width="131" height="19" viewBox="0 0 131 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.5775 2.97922C26.7168 -1.31799 32.4904 -0.172071 34.4881 3.9268C34.8613 3.39792 35.1687 2.82496 35.6077 2.34014C37.2323 0.467001 39.3178 -0.127997 41.6887 0.40089C44.1475 0.929777 45.772 2.51644 46.5184 4.91847C46.7818 5.73384 46.8696 6.63735 46.8916 7.51883C46.9355 10.8905 46.9135 14.2621 46.9135 17.6338C46.9135 18.339 46.694 18.5153 45.8598 18.5153C44.9597 18.5153 44.7621 18.361 44.7621 17.6118C44.7402 14.196 44.7402 10.8023 44.7621 7.36457C44.7621 5.82198 44.345 4.45569 43.1596 3.41995C41.8204 2.27403 40.2837 2.00959 38.6592 2.58255C36.903 3.19958 35.8273 4.45569 35.476 6.28476C35.3443 6.98994 35.3004 7.7392 35.3004 8.48846C35.2785 11.4634 35.2785 14.4164 35.2785 17.3694C35.2785 18.3831 34.3345 18.956 33.4783 18.3831C33.2807 18.2508 33.171 17.8321 33.171 17.5456C33.149 14.0418 33.171 10.5599 33.149 7.05605C33.1271 4.25736 30.844 2.14181 28.1218 2.31811C25.5972 2.47237 23.6653 4.60995 23.6653 7.25439C23.6434 10.6481 23.6653 14.0418 23.6434 17.4355C23.6434 17.766 23.5117 18.339 23.358 18.3831C22.875 18.5153 22.3262 18.4712 21.8213 18.361C21.6896 18.339 21.5578 17.8982 21.5578 17.6338C21.5359 15.5844 21.5359 13.557 21.5359 11.5075C21.5359 8.06975 21.5359 4.63199 21.5359 1.21626C21.5359 0.422927 21.7115 0.290705 22.4799 0.378853C22.6774 0.40089 22.875 0.444964 23.0726 0.444964C23.4678 0.444964 23.5995 0.665334 23.5995 1.03996C23.5995 1.34848 23.6214 1.657 23.6214 1.96551C23.5775 2.27403 23.5775 2.53848 23.5775 2.97922Z" fill="white" />
        <path d="M110.182 9.56818C110.182 14.813 105.989 19 100.742 19C95.5393 19 91.3682 14.7909 91.3682 9.56818C91.3682 4.32338 95.5612 0.114319 100.808 0.114319C105.967 0.114319 110.182 4.36745 110.182 9.56818ZM94.3758 9.56818C94.3758 13.1382 97.2516 16.047 100.786 16.047C104.277 16.047 107.24 13.0721 107.218 9.54614C107.218 6.04226 104.255 3.06727 100.764 3.06727C97.2516 3.06727 94.3758 5.99819 94.3758 9.56818Z" fill="#581082" />
        <path d="M118.48 2.73685C119.446 2.05371 120.346 1.23834 121.378 0.709452C124.539 -0.877209 128.579 0.775564 130.203 4.23537C130.73 5.35925 130.971 6.54925 130.971 7.78332C130.971 10.9787 130.971 14.152 130.971 17.3474C130.971 18.2729 130.73 18.5153 129.83 18.5153C129.457 18.5153 129.105 18.5153 128.732 18.5153C128.008 18.4933 127.81 18.317 127.788 17.5677C127.766 16.4659 127.788 15.364 127.788 14.2622C127.788 12.1687 127.81 10.0972 127.788 8.00369C127.766 5.33721 125.791 3.30981 123.266 3.30981C120.697 3.33185 118.524 5.51351 118.524 8.09183C118.524 11.199 118.524 14.3063 118.524 17.4135C118.524 18.339 118.348 18.5153 117.404 18.5153C117.031 18.5153 116.68 18.5153 116.307 18.5153C115.56 18.4933 115.341 18.2729 115.319 17.5016C115.297 16.51 115.319 15.5403 115.319 14.5487C115.319 10.2515 115.319 5.97629 115.319 1.67908C115.319 0.753526 115.56 0.533157 116.482 0.51112C118.305 0.489083 118.37 0.555194 118.48 2.4063C118.48 2.56056 118.48 2.69278 118.48 2.73685Z" fill="#581082" />
        <path d="M14.9061 8.57639C14.8402 4.78604 11.0643 1.59067 7.35425 2.11956C5.66387 2.36197 4.23692 3.06715 3.00756 4.25715C2.65631 4.5877 2.32702 4.71992 1.95381 4.32326C1.36108 3.72826 1.20741 3.17734 1.58061 2.80271C3.33685 1.17197 5.33457 0.0921607 7.77135 0.00401287C10.9984 -0.084135 13.523 1.28216 15.3671 3.88252C17.8478 7.40843 17.6063 12.3447 14.8402 15.6503C10.6911 20.5865 3.3149 19.4186 0.746401 13.4025C0.263436 12.2345 0 11.0225 0 9.76639C0 8.9951 0.241483 8.68658 1.00984 8.59843C1.29523 8.55436 1.58061 8.57639 1.866 8.57639C5.99316 8.57639 10.1203 8.57639 14.2475 8.57639C14.467 8.57639 14.6646 8.57639 14.9061 8.57639ZM2.15139 10.4495C2.45873 13.9093 5.42239 16.7301 8.62752 16.686C11.6351 16.6199 14.6207 13.6229 14.8183 10.4495C10.6033 10.4495 6.41027 10.4495 2.15139 10.4495Z" fill="white" />
        <path d="M50.9746 9.59014C50.9966 4.96238 53.8944 1.23813 58.1532 0.29054C63.2244 -0.833345 68.2736 2.69257 68.998 7.87126C69.6566 12.5872 67.3955 16.7081 63.2683 18.2947C57.6483 20.4544 51.4576 16.5097 51.0405 10.4716C51.0185 10.1411 50.9966 9.83255 50.9746 9.59014ZM53.104 9.52403C53.126 14.3942 57.3849 17.7879 61.9511 16.5318C65.2441 15.6283 67.4174 12.2566 67.0222 8.64255C66.6271 5.02849 63.7293 2.27387 60.2607 2.20776C56.1994 2.14164 53.104 5.31497 53.104 9.52403Z" fill="white" />
        <path d="M74.7719 6.39499C73.9157 6.39499 73.1034 6.37295 72.2912 6.39499C71.7643 6.41703 71.5228 6.24073 71.5228 5.64573C71.5228 5.07277 71.7204 4.87444 72.2692 4.89648C73.0815 4.91851 73.8938 4.89648 74.7499 4.89648C74.7499 3.61833 74.7499 2.36222 74.7499 1.12815C74.7499 0.334824 74.8158 0.246676 75.5841 0.158528C75.65 0.136491 75.7158 0.136491 75.7598 0.136491C76.8135 0.026306 76.9013 0.114454 76.9013 1.19427C76.9013 2.4063 76.9013 3.59629 76.9013 4.87444C77.955 4.87444 78.9868 4.87444 80.0186 4.87444C80.5016 4.87444 80.765 5.05073 80.787 5.57962C80.8089 6.10851 80.5894 6.37295 80.0406 6.37295C79.0307 6.35092 78.0209 6.37295 76.9452 6.37295C76.9233 6.57129 76.9013 6.74758 76.9013 6.90184C76.9013 9.45813 76.9013 12.0144 76.9013 14.5707C76.9013 14.8131 76.9013 15.0555 76.9233 15.2979C77.0111 16.4218 77.5599 16.9948 78.6795 17.127C79.0746 17.1711 79.4918 17.1711 79.9089 17.1931C80.4138 17.2372 80.6114 17.5016 80.5894 18.0085C80.5675 18.4933 80.3479 18.7357 79.8869 18.7357C79.0088 18.6916 78.1307 18.7137 77.2745 18.5594C75.6939 18.2729 74.7719 17.0388 74.7499 15.2098C74.728 12.4772 74.728 9.78868 74.728 7.0561C74.7719 6.8798 74.7719 6.65943 74.7719 6.39499Z" fill="white" />
        <path d="M84.4969 9.48028C84.4969 6.79177 84.4969 4.08123 84.4969 1.39272C84.4969 0.533274 84.5848 0.467163 85.5287 0.467163C86.5166 0.467163 86.6264 0.555311 86.6264 1.41475C86.6264 6.81381 86.6264 12.2129 86.6264 17.5899C86.6264 18.4493 86.5386 18.5154 85.5287 18.5154C84.6067 18.5154 84.5189 18.4273 84.5189 17.5678C84.4969 14.8793 84.4969 12.1688 84.4969 9.48028Z" fill="white" />
      </svg>
    </div>
  )
}
