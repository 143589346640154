import React from 'react'
import './Button.scss'

function noop() {}

export const Button = (props) => {
  const handler = props.click ? props.click : noop
  return (
    <div>
      <button 
      type={props.type && props.type} 
      onClick={handler} 
      className={`btn ${props.class}`}>
        <span className='btn__text'>{props.text}</span>
        <span className='btn__bg'>{props.text}</span>
      </button>
    </div>
  )
}
