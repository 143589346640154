export const data = [{
  id: 1,
  active: true,
  answered: false,
  title: 'Представьтеся, будь-ласка:',
  answers: [
    { id: 1, value: 'Фрілансер', selected: false },
    { id: 2, value: 'Підприємець', selected: false },
    { id: 3, value: 'Власник бізнесу', selected: false },
    { id: 4, value: 'Займаю управлінську посаду', selected: false },
    { id: 5, value: 'Співробітник в компанії', selected: false }
  ]
},
{
  id: 2,
  active: false,
  answered: false,
  title: 'Що зараз потрібно вашому бізнесу?',
  answers: [
    { id: 1, value: 'Аудит сайту', selected: false },
    { id: 2, value: 'Редизайн сайту', selected: false },
    { id: 3, value: 'Створення сайту', selected: false },
    { id: 4, value: 'Поліпшення існуючого сайту', selected: false }
  ]
},
{
  id: 3,
  active: false,
  answered: false,
  title: 'Який тип сайту вас цікавить?',
  answers: [
    { id: 1, value: 'Лендінг: для тестування ідеї або продажу одного продукту', selected: false },
    { id: 2, value: 'Корпоративний сайт: онлайн-представництво компанії', selected: false },
    { id: 3, value: 'Сайт-візитка: класно та коротко про найважливіше', selected: false },
    { id: 4, value: 'Інтернет-магазин: хочу продавати більше та краще', selected: false },
    { id: 5, value: 'Інше: мобільний додаток, веб-сервіс, платформа', selected: false }
  ]
},
{
  id: 4,
  active: false,
  answered: false,
  title: 'У вас є ТЗ (технічне завдання)?',
  answers: [
    { id: 1, value: 'Так є. Я чітко знаю, що мені потрібно.', selected: false },
    { id: 2, value: 'Ні, ще не готове. Потрібна ваша допомога. Допоможете розібратися?', selected: false }
  ]
},
{
  id: 5,
  active: false,
  answered: false,
  title: ' Які функції потрібні для сайту?',
  answers: [
    { id: 1, value: 'Форма зв\'язку, віджети (онлайн-чати, наприклад)', selected: false },
    { id: 2, value: 'Форма зв\'язку, віджети, інтеграція зі сторонніми сервісами', selected: false },
    { id: 3, value: 'Форма зв\'язку, віджети, інтеграція зі сторонніми сервісами, корзина та онлайн-оплата', selected: false }
  ]
},
{
  id: 6,
  active: false,
  answered: false,
  title: 'У якій ніші ви працюєте?',
  answers: [
    { id: 1, value: 'Надаю послуги', selected: false },
    { id: 2, value: 'Продаю товари', selected: false },
    { id: 3, value: 'Інфобізнес', selected: false },
    { id: 4, value: 'Інше', selected: false }
  ]
},
{
  id: 7,
  active: false,
  answered: false,
  title: 'Скільки часу маєте на вдосконалення сайту?',
  answers: [
    { id: 1, value: '7-10 днів', selected: false },
    { id: 2, value: '14-21 днів', selected: false },
    { id: 3, value: 'Від 21 дня', selected: false }
  ]
},
{
  id: 8,
  active: false,
  answered: false,
  title: 'Ви знаєте, як можна збільшити продажі та конверсію онлайн з новим сайтом?',
  answers: [
    { id: 1, value: 'Знаю, тому хочу співпрацювати з вами', selected: false },
    { id: 2, value: 'Не знаю, як це допоможе моєму бізнесу', selected: false },
    { id: 3, value: 'Уже працюю онлайн, але хочу досягти більшого', selected: false }
  ]
}
]