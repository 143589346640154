import React from 'react'
import QuizNav from '../QuizNav/QuizNav'
import { DefaultScreen } from '../Screen/DefaultScreen/DefaultScreen'
import { ProgressBar } from '../ProgressBar/ProgressBar'
import { usePrev } from '../../hooks/usePrev'
import './Quiz.scss'

export default function Quiz(props) {
  return (
    <div className='quiz'>
      <ProgressBar counter={props.id} total={props.total} />
      <div className='screen-wrapper'>
        <DefaultScreen
          key={props.questions[props.id].id}
          question={props.questions[props.id]}
          active={props.questions[props.id].active}
          prev={usePrev(props.id)}
          cur={props.id}
        />
      </div>
      <QuizNav
        prevHandler={props.prevHandler}
        nextHandler={props.nextHandler}
        answered={props.questions[props.id].answered}
      />
    </div>
  )
}
