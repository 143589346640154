export const fetchData = (data, cb) => {

  const url = 'https://quiz.emotion-agency.com/api/telegram.php'

  const finalData = {
    name: data[1].name,
    phone: data[1].phone,
    answers: [],
  }

  data[0].map((elem) => {
    return elem.answers.forEach((el) => {
      if (el.selected) {
        finalData.answers.push({ title: elem.title, value: el.value })
      }
    })
  })

  const request = async () => {
    try {
      await fetch(url, {
        method: 'POST',
        body: 'param=' + JSON.stringify(finalData),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      cb()
    }
    catch(error) {
      console.log(error)
      alert('Нажаль, виникла помилка:( Спробуйте ще раз.')
    }
  }

  request()
}
