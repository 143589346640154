import React, { useContext } from 'react'
import './RadioBtn.scss'

import SelectedContext from '../../context/SelectedContext'

export function RadioBtn(props) {
  const char = [...Array(26)].map((_, i) => String.fromCharCode(i + 65))

  const { selectItem } = useContext(SelectedContext)

  const clickHandler = () => {
    selectItem(props.id - 1)
  }

  return (
    <label
      onChange={clickHandler}
      className={`container ${props.classes.join(' ')}`}
    >
      <span className='default-screen__item-text'>
        <span className='default-screen__item-char'>
          {char[props.id - 1]}.{' '}
        </span>
        {props.text}
      </span>
      <input type='radio' defaultChecked={props.selected} name='radio' />
      <span className='checkmark'></span>
      <span className='radio-bg'>
        {char[props.id - 1]}. {props.text}
      </span>
    </label>
  )
}
