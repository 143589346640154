import { useEffect } from 'react'

export const useScreenMount = (screen, visible) => {

  useEffect(() => {
    if(visible) {
    const timeout = setTimeout(() => {
      screen.current && screen.current.classList.add('active')
    }, 10)
    return () => {
      clearTimeout(timeout)
    }
    }

  }, [screen, visible])
}
