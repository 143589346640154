import React from 'react'
import CountUp from 'react-countup'
import './ProgressBar.scss'

export function ProgressBar(props) {

  const percent = Math.round((props.counter / props.total) * 100)

  return (
    <div className='progress-bar'>
      <div className='e-container'>
        <div className='progress-bar__lines'>
          <div className='progress-bar__full-line'></div>
          <div
            style={{ width: percent + '%' }}
            className='progress-bar__progress-line'
          ></div>
        </div>
        <div className='progress-bar__ui'>
          <div className='progress-bar__percent'>
            Готово: <CountUp 
            end={percent} 
            preserveValue={true}
            useEasing={false} 
            duration={0.4}
            />%
          </div>
          <div className='progress-bar__numbers'>
            {props.counter + 1} из {props.total}
          </div>
        </div>
      </div>
    </div>
  )
}
