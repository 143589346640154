import React from 'react'
import './QuizNav.scss'
import Arrow from '../Arrow'
import { Logo } from '../Logo'

export default function QuizNav({ prevHandler, nextHandler, answered }) {
  return (
    <div className="quiz-nav">
      <div className="e-container">
        <button
          aria-label="назад"
          className="quiz-nav__btn quiz-nav__btn--outline"
          onClick={prevHandler}
        >
          <Arrow color="white" />
        </button>
        <Logo />
        <button
          disabled={!answered}
          aria-label="дальше"
          className="quiz-nav__btn"
          onClick={nextHandler}
        >
          <Arrow color="var(--dark-purple)" />
        </button>
      </div>
    </div>
  )
}
