import React, { useRef } from 'react'
import './DefaultScreen.scss'

import { RadioBtn } from '../../RadioBtn/RadioBtn'
import { useScreenMount } from '../../../hooks/useScreenMount'

export function DefaultScreen(props) {
  const screen = useRef(null)
  // const someFunc = (cur, prev) => {
  //   if(cur > prev) {
  //     console.log('right')
  //   } else if (cur < prev) {
  //     console.log('left')
  //   }
  // }

  // useEffect(() => {
  //   someFunc(props.cur, props.prev)
  // }, [props.cur, props.prev])

  useScreenMount(screen, true)
  
    return (
      <div ref={screen} className='screen default-screen'>
        <div className='default-screen__content'>
          <h2 className='default-screen__h2'>{props.question.title}</h2>
          <ul className='default-screen__items'>
            {props.question.answers.map((answer) => {
              return (
                <li key={answer.id} className='default-screen__item'>
                  <RadioBtn
                    id={answer.id}
                    classes={[answer.selected && 'active']}
                    text={answer.value}
                    selected={answer.selected}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
}
