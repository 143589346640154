import React, { useRef, useState } from 'react'
import { Form } from '../../Form/Form'
import './FinalScreen.scss'
import { Alert } from '../../Alert/Alert'
import { animateIn, animateOut } from '../../Alert/AlertAnim'
import { useScreenMount } from '../../../hooks/useScreenMount'

export function FinalScreen({ data }) {
  const [alert, setAlert] = useState({
    classes: [],
    text: '',
    visible: false,
  })

  const screen = useRef(null)

  useScreenMount(screen, true)

  const showAlert = (classes, text) => {
    const newAlert = { ...alert }
    newAlert.visible = true
    newAlert.classes = classes
    newAlert.text = text
    setAlert(newAlert)

    animateIn()

    setTimeout(() => {
      hideAlert()
    }, 4000)
  }

  const hideAlert = () => {
    
    animateOut(() => {
      const newAlert = { ...alert }
      newAlert.visible = false
      setAlert(newAlert)
    })
  }

  return (
    <>
      {alert.visible && (
        <Alert hide={hideAlert} classes={alert.classes} text={alert.text} />
      )}
      <div ref={screen} className='screen final-screen'>
        <div className='final-screen__content'>
          <h1 className='h1 final-screen__h1'>Дякуємо, що пройшли тест!</h1>
          <div className='final-screen__content-wrapper'>
            <div className='final-screen__content-left'>
              <p className='big-text'>
                Напишіть ваше ім'я та номер телефону, будь ласка.
              </p>
              <p className='final-screen__small-text'>
                З вами зв'яжеться Роман — співзасновник та Project Manager
                агенції Emotion, й розповість, як можна збільшити конверсію
                вашого бізнесу онлайн.
              </p>
            </div>
            <div className='final-screen__content-right'>
              <Form data={data} alert={showAlert} hideAlert={hideAlert} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
