import React, { useRef } from 'react'
import { useScreenMount } from '../../../hooks/useScreenMount'
import {Button} from '../../Button/Button'
import './HelloScreen.scss'

export function HelloScreen(props) {

  const screen = useRef(null)

  useScreenMount(screen, true)
  
  return (
    <div ref={screen} className="screen hello-screen">
      <div className="hello-screen__content">
      <h1 className="h1 hello-screen__h1">Привіт!</h1>
      <p className="big-text hello-screen__big-text">Лише 8 питань — і ми безкоштовно вигадаємо концепцію вашого нового сайту</p>
      <div className="hello-screen__default-text">
      <p>Щоб збільшити конверсію та покращити імідж бренду.</p>
      <p>Тест займе близько 1 хвилини, але заощадить вам мінімум 1 тиждень на&nbsp;аналіз вашої бізнес-ситуації</p>
      </div>
      <Button
        click={props.startHandler}
        class={'hello-screen__btn'}
        text={'Розпочати'}
      />
      {/* <button onClick={props.startHandler} className="btn hello-screen__btn">Розпочати</button> */}
      </div>
    </div>
  )
}