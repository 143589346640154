import React from 'react'
import './App.scss'
import Main from './Main'
import { useHeight } from './hooks/useHeight'

/**
 * @todo
 * 1. Сделать отельный компонент инпута
 * 2. Переписать всё приложение на редакс
 * 3. Добавить анимации при переходах между
 */

function App() {
  useHeight()

  return (
    <div className='app'>
      <div className='bg-gradient'></div>
      <div className='e-container'>
        <Main />
      </div>
    </div>
  )
}

export default App
