import React, { useState } from 'react'
import { HelloScreen } from './components/Screen/HelloScreen/HelloScreen';
import { data } from './data'
import { FinalScreen } from './components/Screen/FinalScreen/FinalScreen'
import Quiz from './components/Quiz/Quiz'
import SelectedContext from './context/SelectedContext'

let counter = 0

function Main() {
  const [questions, setQuestions] = useState(data)
  const [screen, setScreen] = useState('start')

  const nextHandler = () => {
    // setTimeout(() => {
      if (counter >= questions.length - 1) {
        counter = questions.length - 1
        setScreen('finish')
      } else {
        questions[counter].active = false
        questions[counter + 1].active = true
        counter++
      }
      setQuestions([...questions])
    // }, 300)

  }

  const prevHandler = () => {

    if (counter <= 0) {
      counter = 0
    } else {
      questions[counter].active = false
      questions[counter - 1].active = true
      counter--
    }

    setQuestions([...questions])
  }

  const selectItem = (id) => {

    questions[counter].answers.map(el => el.selected = false)
    questions[counter].answers[id].selected = true
    questions[counter].answered = true
    setQuestions([...questions])
    nextHandler()
  }


const actualScreen = () => {
  if (screen === 'start') {
    return <HelloScreen startHandler={setScreen.bind(null, 'quiz')} />
  } else if(screen === 'quiz') {
    return (
      <>
        <Quiz
          questions={questions}
          prevHandler={prevHandler}
          nextHandler={nextHandler}
          id={counter}
          total={questions.length}
        />
      </>
    )
  } else {
    return <FinalScreen data={questions} />
  }
}

  return (
    <SelectedContext.Provider value={{ selectItem }}>
        {actualScreen()}
    </SelectedContext.Provider>
  );
}

export default Main
